<script lang="ts" setup>
import { useContentStore } from "@/store/content";
import { usePrescriptionStore } from "@/store/prescription";
import { useDeclarationStore } from "@/store/declaration";
import { useDismissalStore } from "@/store/dismissal";
import { storeToRefs } from "pinia";

const contentStore = useContentStore();
const prescriptionStore = usePrescriptionStore();
const declarationStore = useDeclarationStore();
const dismissalStore = useDismissalStore();

const { loading: contentLoading } = storeToRefs(contentStore);
const { loading: prescriptionLoading } = storeToRefs(prescriptionStore);
const { loading: declarationLoading } = storeToRefs(declarationStore);
const { loading: dismissalLoading } = storeToRefs(dismissalStore);

const isLoading = computed(() => {
  return (
    contentLoading.value ||
    prescriptionLoading.value ||
    declarationLoading.value ||
    dismissalLoading.value
  );
});

onBeforeRouteLeave(() => {
  // because of persistant state
  contentLoading.value = false;
  prescriptionLoading.value = false;
  declarationLoading.value = false;
  dismissalLoading.value = false;
});
</script>

<template>
  <div v-if="isLoading" class="spinner-overlay">
    <ProgressSpinner color="primary" />
  </div>
</template>

<style lang="scss" scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($primary-text, 0.5);
}
</style>
