
// @ts-nocheck


export const localeCodes =  [
  "pl",
  "ua"
]

export const localeMessages = {
  "pl": [{ key: "../lang/pl.json", load: () => import("../lang/pl.json" /* webpackChunkName: "locale__builds_nasz_gabinet_nasz_gabinet_frontend_lang_pl_json" */), cache: true }],
  "ua": [{ key: "../lang/ua.json", load: () => import("../lang/ua.json" /* webpackChunkName: "locale__builds_nasz_gabinet_nasz_gabinet_frontend_lang_ua_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../nuxt.i18n.config.ts?hash=39b2f803&config=1" /* webpackChunkName: "__nuxt_i18n_config_ts_39b2f803" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt.i18n.config.ts",
  "locales": [
    {
      "code": "pl",
      "name": "Polski",
      "files": [
        "lang/pl.json"
      ]
    },
    {
      "code": "ua",
      "name": "Ukraina",
      "files": [
        "lang/ua.json"
      ]
    }
  ],
  "defaultLocale": "pl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "pl",
      "name": "Polski",
      "files": [
        {
          "path": "lang/pl.json"
        }
      ]
    },
    {
      "code": "ua",
      "name": "Ukraina",
      "files": [
        {
          "path": "lang/ua.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

