import { $api } from '@/utils/fetch';
import { useI18nStore } from '@/store/i18n';
import type { Option, Drug } from '@/types/common';
import { buildFormData } from '@/utils/formData';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import axios from 'axios';

export interface Form {
  drugIds: number[];
}

interface Condition {
  possibleAnswerId: number;
  possibleAnswerQuestionId: number;
}

export interface SymptomQuestion {
  id: number;
  key: string;
  content: string;
  placeholder: string;
  hint: string;
  cols: number;
  fieldType: string;
  required: boolean;
  conditions: Condition[];
  possibleAnswers: Option<number>[];
}
export interface SymptomAnswer {
  questionId: number;
  possibleAnswerIds: number[];
  answer: string | null;
}

export const usePrescriptionStore = defineStore('prescription', {
  state: () => ({
    loading: false,
    prescriptionFormId: null,
    isPsychodelic: false,
    selectedDrugs: <Drug[]>[],
    symptomQuestions: <SymptomQuestion[]>[],
    results: <SymptomAnswer[]>[],
    formAttachments: <File[]>[],
  }),
  actions: {
    async fetchSymptomsForm(drugCategoryId: number | null) {
      this.loading = true;
      const { locale } = useI18nStore();
      try {
        const { id, questions, isPsychodelic } = await $api('/prescription_forms', {
          method: 'GET',
          headers: {
            'Accept-Language': locale,
          },
          query: { drugCategoryId },
        });
        this.formAttachments = [];
        if (this.prescriptionFormId !== id) {
          this.prescriptionFormId = id;
          this.symptomQuestions = questions;
          this.isPsychodelic = isPsychodelic;
          const getPossibleAnswerIds = (question: SymptomQuestion) => {
            return question.fieldType === 'switch' ? [question.possibleAnswers[0]?.value] : [];
          };
          this.results = questions.map((question: SymptomQuestion) => ({
            questionId: question.id,
            possibleAnswerIds: getPossibleAnswerIds(question),
            answer: null,
          }));
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject();
      } finally {
        this.loading = false;
      }
    },
    submitForm(token: string) {
      interface Result {
        questionId: number;
        possibleAnswerIds?: number[];
        answer?: string | null;
      }
      const filteredResults: Result[] = cloneDeep(this.results).filter((result) =>
        this.getVisibleQuestionIds.includes(result.questionId),
      );
      filteredResults.forEach((result) => {
        if (result.answer === null) {
          delete result.answer;
        } else {
          delete result.possibleAnswerIds;
        }
        return result.answer;
      });
      const formData = new FormData();
      const params = {
        prescriptionFormId: this.prescriptionFormId,
        drugIds: this.selectedDrugs.map((drug) => drug.id),
        results: filteredResults,
        files: this.formAttachments,
        token,
      };
      buildFormData(formData, params);

      this.loading = true;
      const { locale } = useI18nStore();
      const baseUrl = import.meta.env.VITE_APP_API_URL;

      return axios
        .post(baseUrl + '/prescriptions/send_prescription', formData, {
          headers: {
            'Accept-Language': locale,
          },
        })
        .then(() => {
          sessionStorage.removeItem('prescription');
          this.prescriptionFormId = null;
          this.selectedDrugs = [];
          this.symptomQuestions = [];
          this.results = [];
          this.formAttachments = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  getters: {
    getFormAsObject(state) {
      return Object.fromEntries(
        state.results.map((result) => [
          result.questionId,
          result.answer || result.possibleAnswerIds,
        ]),
      );
    },
    getVisibleQuestionIds(state): number[] {
      return state.symptomQuestions
        .filter((question) => {
          if (!question.conditions.length) return true;
          return question.conditions.every((condition) => {
            const { possibleAnswerQuestionId, possibleAnswerId } = condition;
            const answerValue = get(this.getFormAsObject, possibleAnswerQuestionId);
            if (Array.isArray(answerValue)) {
              return answerValue.includes(possibleAnswerId);
            }
            // currently conditions are only based on arrays of numbers
          });
        })
        .map((question) => question.id);
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
  debounce: {
    submitForm: [
      500,
      {
        isImmediate: true,
      },
    ],
  },
});
