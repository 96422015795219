import type { PersonalData, Address } from '@/types/forms';
import { $api } from '@/utils/fetch';
import { useI18nStore } from '@/store/i18n';
import parsePhoneNumber from 'libphonenumber-js';

export interface Form extends PersonalData {
  address: Address;
  checkedConsentIds: number[];
  checkedStatementIds: number[];
  description?: string;
}

const initialForm: Form = {
  fullName: '',
  peselNumber: '',
  phoneNumber: '',
  email: '',
  description: '',
  address: {
    streetName: '',
    buildingNumber: '',
    apartmentNumber: '',
    city: '',
    postalCode: '',
  },
  checkedConsentIds: [],
  checkedStatementIds: [],
};

export const useDeclarationStore = defineStore('declaration', {
  state: () => ({
    loading: false,
    hasIntroBeenCompleted: false,
    form: <Form>initialForm,
  }),
  actions: {
    async submitForm(): Promise<{ guest: { token: string } }> {
      const { number } = parsePhoneNumber(this.form.phoneNumber) || {};
      const { locale } = useI18nStore();
      const query = {
        ...this.form,
        phoneNumber: number,
      };
      if (!this.form.description) delete query.description;
      this.loading = true;
      try {
        const data: { guest: { token: string } } = await $api('declarations/send_declaration', {
          method: 'POST',
          headers: {
            'Accept-Language': locale,
          },
          query,
        });
        sessionStorage.removeItem('declaration');
        this.form = initialForm;
        this.hasIntroBeenCompleted = false;
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        this.loading = false;
      }
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
  debounce: {
    submitForm: [
      500,
      {
        isImmediate: true,
      },
    ],
  },
});
