import humps from 'humps';

const isArray = (data) => data && typeof data === 'object' && Array.isArray(data);
const isPureObject = (data) =>
  data &&
  typeof data === 'object' &&
  !Array.isArray(data) &&
  !(data instanceof Date) &&
  !(data instanceof File);

const buildFormData = (formData, data, parentKey) => {
  if (isPureObject(data)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else if (isArray(data)) {
    data.forEach((item) => {
      buildFormData(formData, item, `${parentKey}[]`);
    });
  } else {
    const value = data === null ? '' : data;
    formData.append(humps.decamelize(parentKey), value);
  }
};

export { buildFormData };
