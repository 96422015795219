import qs from 'qs';
import humps from 'humps';
import { $fetch } from 'ofetch';

const getQueryParams = (params) => {
  return (
    '?' +
    qs.stringify(humps.decamelizeKeys(params), {
      arrayFormat: 'brackets',
    })
  );
};

export const $api = $fetch.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  onRequest: (config) => {
    if (config.options.body) {
      config.options.body = humps.decamelizeKeys(config.options.body);
    }
    if (config.options.params) {
      // ofetch cannot serialize array params like that: "a[]:1, a[]:2", so they have to be added to url
      config.request += getQueryParams(config.options.params);
      delete config.options.params;
    }
    if (config.options.query) {
      config.request += getQueryParams(config.options.query);
      delete config.options.query;
    }
    return config;
  },
  onResponse: (config) => {
    config.response._data = humps.camelizeKeys(config.response._data);
    return config;
  },
});
