export const useI18nStore = defineStore('i18n', {
  state: () => ({
    locale: 'pl',
  }),
  actions: {
    setLocale(locale: string) {
      this.locale = locale;
    },
  },
});
