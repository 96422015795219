import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  Sentry.init({
    app: [vueApp],
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router),
        tracingOrigins: ['localhost', import.meta.env.VITE_APP_DOMAIN, /^\//],
      }),
    ],
    logErrors: false, // Note that this doesn't seem to work with nuxt 3
    tracesSampleRate: 1.0, // Sentry recommends adjusting this value in production
    debug: false, // Enable debug mode
    environment: import.meta.env.MODE, // Set environment
    // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    },
  });
});
