<script lang="ts" setup>
import { useContentStore } from "@/store/content";

const { locale } = useI18n();
const contentStore = useContentStore();
onBeforeMount(() => {
  contentStore.fetchData();
});
watch(locale, () => {
  contentStore.fetchData();
});

const dialogRef = ref();
provide("dialogRef", dialogRef);
</script>

<template>
  <div>
    <NuxtPage />
    <Toast />
    <DynamicDialog ref="dialogRef" />
    <TheSpinner />
  </div>
</template>

<style lang="scss">
body {
  margin: 0;
}
</style>
