import { $api } from '@/utils/fetch';
import { useI18nStore } from '@/store/i18n';
import formatDate from '@/utils/formatDate';

export interface DateRange {
  dateFrom: string;
  dateTo: string;
}

export interface Form {
  description: string;
  durationOfSymptoms: string;
  chronicDiseasesOccurance: boolean | undefined;
  permanentMedicationIntake: boolean | undefined;
  dateRange: DateRange;
  patientType: 'Student' | 'Employee';
  nipNumbers: string[];
}

const initialForm: Form = {
  description: '',
  durationOfSymptoms: '',
  chronicDiseasesOccurance: undefined,
  permanentMedicationIntake: undefined,
  dateRange: {
    dateFrom: formatDate(new Date()),
    dateTo: formatDate(new Date()),
  },
  patientType: 'Employee',
  nipNumbers: [''],
};

export const useDismissalStore = defineStore('dismissal', {
  state: () => ({
    loading: false,
    form: <Form>initialForm,
  }),
  actions: {
    updateFormValue<Key extends keyof Form>(key: Key, value: Form[Key]) {
      this.form[key] = value;
    },
    mergeForms(form: Partial<Form>) {
      Object.entries(form).forEach(([key, value]) => {
        this.updateFormValue(key as keyof Form, value);
      });
    },
    submitForm(token: string) {
      const { locale } = useI18nStore();
      interface FormQuery extends Omit<Form, 'nipNumbers'> {
        token: string;
        nipNumbers?: string[];
      }
      const query: FormQuery = {
        ...this.form,
        token,
      };
      if (query.patientType === 'Student') delete query.nipNumbers;
      this.loading = true;
      return $api('/sick_leaves/report', {
        method: 'POST',
        headers: {
          'Accept-Language': locale,
        },
        query,
      })
        .then(() => {
          sessionStorage.removeItem('dismissal');
          this.form = initialForm;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
  debounce: {
    submitForm: [
      500,
      {
        isImmediate: true,
      },
    ],
  },
});
