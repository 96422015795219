import { $api } from '@/utils/fetch';
import { useI18nStore } from '@/store/i18n';

import type {
  Drug,
  DrugCategory,
  FileParams,
  ReasonTypeStatement,
  YearlyOccuranceTypeStatement,
  Consent,
  Pagination,
} from '@/types/common';

interface State {
  loading: boolean;
  drugCategories: DrugCategory[];
  reasonTypeStatements: ReasonTypeStatement[];
  yearlyOccuranceTypeStatements: YearlyOccuranceTypeStatement[];
  consents: Consent[];
  drugsList: Drug[];
  drugsPagination: Pagination;
  fileParams: FileParams;
}

export const useContentStore = defineStore('content', {
  state: (): State => ({
    loading: false,
    drugCategories: [],
    reasonTypeStatements: [],
    yearlyOccuranceTypeStatements: [],
    consents: [],
    drugsList: [],
    fileParams: <FileParams>{},
    drugsPagination: <Pagination>{},
  }),
  actions: {
    fetchData() {
      const { locale } = useI18nStore();
      this.loading = true;
      return $api('filters', {
        method: 'GET',
        headers: {
          'Accept-Language': locale,
        },
      })
        .then((data: State) => {
          this.drugCategories = data.drugCategories;
          this.reasonTypeStatements = data.reasonTypeStatements;
          this.yearlyOccuranceTypeStatements = data.yearlyOccuranceTypeStatements;
          this.consents = data.consents;
          this.fileParams = data.fileParams;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchDrugs({
      drugCategoryId,
      expression,
      page,
      drugIds,
      setLoader = true,
    }: {
      drugCategoryId?: number;
      expression?: string;
      page?: number;
      setLoader?: boolean;
      drugIds?: number[];
    } = {}) {
      const { locale } = useI18nStore();
      if (setLoader) this.loading = true;
      return $api('/drugs', {
        method: 'GET',
        headers: { 'Accept-Language': locale },
        query: {
          page,
          filters: {
            drugCategoryId,
            drugIds,
          },
          search: {
            expression,
          },
        },
      })
        .then((data) => {
          this.drugsList = data.collection;
          this.drugsPagination = data.pagination;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
