import { default as declarationYF8k4WJBETMeta } from "/builds/nasz_gabinet/nasz_gabinet_frontend/pages/declaration.vue?macro=true";
import { default as dismissalT4xnymQgaHMeta } from "/builds/nasz_gabinet/nasz_gabinet_frontend/pages/dismissal.vue?macro=true";
import { default as index6HE48k7Vd0Meta } from "/builds/nasz_gabinet/nasz_gabinet_frontend/pages/index.vue?macro=true";
import { default as prescriptionhD8HM4YuYGMeta } from "/builds/nasz_gabinet/nasz_gabinet_frontend/pages/prescription.vue?macro=true";
export default [
  {
    name: declarationYF8k4WJBETMeta?.name ?? "declaration___pl",
    path: declarationYF8k4WJBETMeta?.path ?? "/declaration",
    meta: declarationYF8k4WJBETMeta || {},
    alias: declarationYF8k4WJBETMeta?.alias || [],
    redirect: declarationYF8k4WJBETMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/declaration.vue").then(m => m.default || m)
  },
  {
    name: declarationYF8k4WJBETMeta?.name ?? "declaration___ua",
    path: declarationYF8k4WJBETMeta?.path ?? "/ua/declaration",
    meta: declarationYF8k4WJBETMeta || {},
    alias: declarationYF8k4WJBETMeta?.alias || [],
    redirect: declarationYF8k4WJBETMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/declaration.vue").then(m => m.default || m)
  },
  {
    name: dismissalT4xnymQgaHMeta?.name ?? "dismissal___pl",
    path: dismissalT4xnymQgaHMeta?.path ?? "/dismissal",
    meta: dismissalT4xnymQgaHMeta || {},
    alias: dismissalT4xnymQgaHMeta?.alias || [],
    redirect: dismissalT4xnymQgaHMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/dismissal.vue").then(m => m.default || m)
  },
  {
    name: dismissalT4xnymQgaHMeta?.name ?? "dismissal___ua",
    path: dismissalT4xnymQgaHMeta?.path ?? "/ua/dismissal",
    meta: dismissalT4xnymQgaHMeta || {},
    alias: dismissalT4xnymQgaHMeta?.alias || [],
    redirect: dismissalT4xnymQgaHMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/dismissal.vue").then(m => m.default || m)
  },
  {
    name: index6HE48k7Vd0Meta?.name ?? "index___pl",
    path: index6HE48k7Vd0Meta?.path ?? "/",
    meta: index6HE48k7Vd0Meta || {},
    alias: index6HE48k7Vd0Meta?.alias || [],
    redirect: index6HE48k7Vd0Meta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6HE48k7Vd0Meta?.name ?? "index___ua",
    path: index6HE48k7Vd0Meta?.path ?? "/ua",
    meta: index6HE48k7Vd0Meta || {},
    alias: index6HE48k7Vd0Meta?.alias || [],
    redirect: index6HE48k7Vd0Meta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: prescriptionhD8HM4YuYGMeta?.name ?? "prescription___pl",
    path: prescriptionhD8HM4YuYGMeta?.path ?? "/prescription",
    meta: prescriptionhD8HM4YuYGMeta || {},
    alias: prescriptionhD8HM4YuYGMeta?.alias || [],
    redirect: prescriptionhD8HM4YuYGMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/prescription.vue").then(m => m.default || m)
  },
  {
    name: prescriptionhD8HM4YuYGMeta?.name ?? "prescription___ua",
    path: prescriptionhD8HM4YuYGMeta?.path ?? "/ua/prescription",
    meta: prescriptionhD8HM4YuYGMeta || {},
    alias: prescriptionhD8HM4YuYGMeta?.alias || [],
    redirect: prescriptionhD8HM4YuYGMeta?.redirect || undefined,
    component: () => import("/builds/nasz_gabinet/nasz_gabinet_frontend/pages/prescription.vue").then(m => m.default || m)
  }
]